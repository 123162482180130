<template>
  <!-- 植物记录 -->
  <div class="cont">
    <div class="record">
      <TopBanner @toggle="showMask = !showMask"/>
      <div class="record-wrapper">
        <div class="record-content">
          <van-swipe @change="onChange">
            <van-swipe-item v-for="(image, index) in images" :key="index">
              <img :src="image" alt="" class="preview-img">
            </van-swipe-item>
            <template #indicator>
              <div class="custom-indicator">{{ current + 1 }} / {{ images.length }}</div>
            </template>
          </van-swipe>
          <!-- <van-image-preview v-model="show" :images="images" :asyncClose="true" /> -->
          <div :class="['record-info', isExpand ? 'expand-record' : 'collapse-record']" v-if="lifeRecordInfo">
            <div class="page3">
              <div class="user-info">
                <div
                  class="avatar-info"
                  @click="
                    $router.push({
                      path: '/html/app/homepage',
                      query: { id: lifeRecordInfo.author.uid },
                    })
                  "
                >
                  <van-image
                    round
                    width="40"
                    height="40"
                    :src="lifeRecordInfo.author.avatar"
                    class="img"
                  />
                  <div class="txt">
                    <div class="name">{{ lifeRecordInfo.author.nickname | maxLen }}</div>
                    <div class="time">{{ dataFormat(new Date(lifeRecordInfo.createTime), 'yyyy年MM月dd日') }}</div>
                  </div>
                </div>
                <div
                  class="btns add"
                  @click="
                    $router.push({
                      path: '/html/app/homepage',
                      query: { id: lifeRecordInfo.author.uid },
                    })
                  "
                >查看更多</div>
              </div>
            </div>
            <div class="record-title" v-html="lifeRecordInfo.title"></div>
            <div :class="{'record-text': true, 'ellipsis-text': expandVisible}" id="record-text" v-html="lifeRecordInfo.content"></div>
            <div class="bottom-btn" v-if="expandVisible">
              <div class="expand-collapse-btn" @click="isExpand = !isExpand">{{ isExpand ? '收起' : '展开全文' }}</div>
            </div>
          </div>
        </div>
        <div class="page4" @click="open_app()">打开APP，给TA留言点赞</div>
      </div>
    </div>
    <TipMask :visible="showMask" @toggle="showMask = !showMask"></TipMask>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import TopBanner from "../../components/TopBanner.vue";
import TipMask from '../../components/TipMask.vue';
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
Vue.use(ImagePreview);
export default {
  components: {
    TopBanner,
    TipMask
  },
  mounted() {
    this.appUrl.onlyMo(); //只允许移动端打开
    if (
      this.$route.query.id == null ||
      this.$route.query.id == undefined ||
      this.$route.query.id == ""
    ) {
      this.appUrl.alertErr("无权访问");
      return;
    }
    document.title = this.record_title;
    this.id_record = this.$route.query.id;
    this.getLifeRecordInfo(this.id_record);
  },
  data() {
    return {
      current: 0,
      expandVisible: false,
      show: true,
      showMask: false,
      record_title: "植物成长记", //页面title
      page_bool: true,
      page_num: 1,
      page_size: 20,
      id_record: null,
      url_web_detail: this.apiUrl.url_web_detail,
      down_url_iphone: this.appUrl.down_url_iphone, //Iphone下载链接
      down_url_android: this.appUrl.down_url_android, //Android下载链接
      url_iphone: "xzw://smallplants.cn/app/plant?id=" + this.$route.query.id + "&functionType=" + 2, //Iphone唤起APP链接
      url_android: "xzw://smallplants.cn/app/plant?id=" + this.$route.query.id + "&functionType=" + 2, //Android唤起APP链接
      user_section: {
        //用户信息
        user_id: null,
        nickName: null,
        img: null,
        title: null,
        txt: null,
        llNum: null,
        jlNum: null,
        datalist: null,
      },
      lifeRecordInfo: null,
      images: [],
      isExpand: false
    };
  },
  filters: {
    upString(v) {
      let text = ``;
      for (let i = 0; i < v.split("").length; i++) {
        if (i == v.split(" ").length - 1) {
          text = `${text}${v.split(" ")[i]}`;
        } else {
          text = `${text}${v.split(" ")[i]}/n`;
        }
        return text;
      }
    },
    maxLen(v) {
      if (v && v.length >= 10) {
        return v.substring(0, 9) + "...";
      } else {
        return v;
      }
    }
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    getLifeRecordInfo(id) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "正在加载",
      });
      this.$axios
        .get(`${this.apiUrl.url_web_liferecord_detail}?id=${id}`)
        .then((res) => {
          Toast.clear();
          if (res.data.code == 200) {
            this.lifeRecordInfo = res.data.data
            this.images = this.lifeRecordInfo.images.map(img => img.largeUrl)
            this.$nextTick(() => this.checkExpandVisible())
          } else {
            this.appUrl.alertErr(res.data.msg);
          }
        })
        .catch(() => {
          Toast.clear();
          this.appUrl.alertErr("网络异常");
          console.log("err");
        });
    },
    checkExpandVisible() {
      const textContent = document.getElementById("record-text")
      const textContentHeight = textContent.getBoundingClientRect().height
      if (textContentHeight > 22 * 1) {
        // 超出1行
        this.expandVisible = true
      }
    },
    dataFormat(dateTime, format) {
      var o = {
        "M+": dateTime.getMonth() + 1, //月份
        "d+": dateTime.getDate(), //日
        "h+": dateTime.getHours(), //小时
        "m+": dateTime.getMinutes(), //分
        "s+": dateTime.getSeconds(), //秒
        "q+": Math.floor((dateTime.getMonth() + 3) / 3), //季度
        "S": dateTime.getMilliseconds() //毫秒
      };
      if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (dateTime.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o)
        if (new RegExp("(" + k + ")").test(format)) format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return format;
    },
    open_app() {
      //下载app
      var ua = navigator.userAgent.toLowerCase();
      //系统判断
      if ((ua.indexOf('qq')>-1 && ua.indexOf('mqqbrowser')<0) ||
        ua.match(/MicroMessenger/i) == 'micromessenger') {
          // qq 或微信浏览器
        this.showMask = true
      } else if (ua.match(/iPhone/i) == "iphone") {
        //iphone
        console.log(ua.match(/iPhone/i));
        var loadTime = new Date();
        window.open(this.url_iphone);
        setTimeout(function () {
          var outTime = new Date();
          if (outTime - loadTime > 800) {
            window.open(this.down_url_iphone);
          }
        }, 1000);
      } else if (ua.match(/Android/i) == "android") {
        //android
        console.log(ua.match(/Android/i));
        loadTime = new Date();
        window.open(this.url_android);
        setTimeout(function () {
          var outTime = new Date();
          if (outTime - loadTime > 800) {
            window.open(this.down_url_android);
          }
        }, 1000);
      } else {
        console.log("请使用移动端登录");
        Toast.fail("请前往移动端下载");
      }
    },
  },
};
</script>
<style scoped>
.cont {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.record {
  width: 100%;
  height: auto;
  font-family: PingFang-SC-Medium, PingFang-SC;
}
.record pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-weight: 500;
}
.record .record-info {
  position: absolute;
  height: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(30, 30, 30, 0.7);
  padding: 7.5px 15px;
  z-index: 9999;
}
/* .record .record-info {
  max-height: 60vh;
} */
.record .page3 .user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
}
.record .page3 .user-info .avatar-info {
  display: flex;
  align-items: center;
}
.record .page3 .user-info .img {
  margin-right: 10px;
}
.record .page3 .user-info .name {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  line-height: 22px;
}
.record .page3 .user-info .time {
  font-size: 12px;
  font-weight: 500;
  color: #B2B2B2;
  line-height: 17px;
}
.record .page3 .user-info .add {
  width: 73px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid #DBDEEB;
  font-size: 13px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #DBDEEB;
  line-height: 24px;
  text-align: center;
}
.record .record-text, .record .record-title {
  color: #FFFFFF;
  display: inline-block;
  text-align: justify;
  line-height: 22px;
}
.record .record-text {
  max-height: 60vh;
  overflow: auto;
  font-size: 14px;
}
.record .record-title {
  margin-top: 7.5px;
}
.record .collapse-record .ellipsis-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.record .bottom-btn {
  text-align: right;
  margin-top: 2.5px;
}
.record .bottom-btn .expand-collapse-btn {
  display: inline-block;
  color: #80869D;
  font-size: 14px;
  padding: 20px 15px;
  margin: -20px -15px;
  min-width: 55px;
}
.record .page4 {
  /* position: fixed; */
  /* bottom: 20px; */
  /* left: 20px; */
  /* right: 20px; */
  margin: 20px;
  height: 44px;
  background: #26262a;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  line-height: 44px;
  text-align: center;
  z-index: 10;
}
/* .record >>> .van-overlay {
  top: 0;
  bottom: 0;
  height: auto;
  position: absolute;
}
.van-image-preview {
  top: 0;
  bottom: 0;
  height: auto;
  position: absolute;
}
.record >>> .van-image-preview__index {
  padding: 1px 5px;
  background-color: rgba(30, 30, 30, 0.7);
  border-radius: 2px;
  top: 20px;
  left: 40px;
} */
.record .record-wrapper {
  margin-top: 48px;
  height: calc(100vh - 48px);
  overflow: auto;
}
.record .record-content {
  height: calc(100vh - 48px);
  position: relative;
}
.custom-indicator {
  position: absolute;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.1);
  padding: 1px 5px;
  background-color: rgba(30, 30, 30, 0.7);
  border-radius: 2px;
  top: 20px;
  left: 40px;
  color: #ffffff;
}
.van-swipe {
  height: calc(100vh - 48px);
  /* text-align: center; */
  background-color: rgba(0,0,0,.9);
}
.preview-img {
  max-width: 100%;
  max-height: calc(100vh - 48px);
}
.van-swipe-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>